import React from 'react';
import moment from 'moment'
import ReactDOM from 'react-dom';
import {  HashRouter } from 'react-router-dom';
import { Button } from '@material-ui/core';
import { createBrowserHistory } from 'history';

import { ToastContainer } from 'react-toastify';

import './theme/scss/main.css';
import 'react-toastify/dist/ReactToastify.css';


import { MuiThemeProvider, responsiveFontSizes, createMuiTheme } from '@material-ui/core/styles';

//theme
import def, { overrides } from './theme/JSS/main';
let theme = createMuiTheme({ ...def.default, ...overrides });
    theme = responsiveFontSizes(theme)

// Force Application time zone to UK
require('moment-timezone')
moment.tz.setDefault('Europe/London')

const history = createBrowserHistory();

//Suspending the applicaation for modules that give me a output
const App = React.lazy(() => import('./pages/main'));

ReactDOM.render(
  <React.StrictMode>
    <MuiThemeProvider theme={theme}>
        <React.Suspense fallback={'loading'}>
          <HashRouter history={history}>
          <ToastContainer
            position="top-right"
            autoClose={5000}
            hideProgressBar={false}
            newestOnTop={false}
            closeOnClick
            rtl={false}
            pauseOnFocusLoss
            draggable
            pauseOnHover />
            <App />
          </HashRouter>
        </React.Suspense>
    </MuiThemeProvider>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();
