//think for the BG
// background-image: linear-gradient(-60deg, #16a085 0%, #f4d03f 100%);

import { unset } from "lodash";

//Invisage Colors
const yellow = "#FFEB00";
const black = "#000000";
const white = "#FFFFFF";
const grey1 = "#282828";
const grey2 = "#505050";
const grey3 = "#787878";
const grey4 = "#A0A0A0";
const grey5 = "#C8C8C8";
const grey6 = "#F0F0F0";
//BOLD WITH YELLO
// const Aqua = "#39c8c6"
// const Orange = "#d3500c";
// const Purple= "#500a2f"

const primary = yellow;
const secondary = black;
const error = "#FF4136";
const warning = "#FFC260";
const success = "#3CD4A0";
const info = "#9013FE";

export const defaultTheme ={
    "breakpoints": {
      "keys": [
        "xs",
        "sm",
        "md",
        "lg",
        "xl"
      ],
      "values": {
        "xs": 0,
        "sm": 601,
        "md": 961,
        "lg": 1281,
        "xl": 1920
      }
    },
    "direction": "ltr",
    "mixins": {
      "toolbar": {
        "minHeight": 56,
        "@media (min-width:0px) and (orientation: landscape)": {
          "minHeight": 48
        },
        "@media (min-width:600px)": {
          "minHeight": 64
        }
      }
    },
    
    overrides: {
      MuiPaper:{
        root:{
          background:black
        }
      },
      MuiTooltip:{
        tooltip:{
          fontSize:'1rem'
        }
      },
      MuiDialog: {
        paperScrollPaper:{
          border: "1px solid rgba(255,235,0,0.5)",
        },  
        paper: {
          // backgroundImage: `url(${img}) !important`,
          background: '#000000', /* fallback for old browsers */
          backgroundSize: `100%`,
          backgroundRepeat: 'no-repeat',
          // boxShadow:'inset 0 0 0 2000px rgba(0,0,0,0.7)',
          border:"1px solid rgba(255,235,0,0.5)",
          // width: "60%",
          // height: "100%",
          display: "block !important",
          flexDirection: "column",
          justifyContent: "flex-start",
          alignItems: "flex-start",
        },
        paperWidthLg:{
          maxWidth:unset,
        }
      },
      MuiButton:{
        root:{
          outline:'none !important',
        }
      },
       
      MuiTab:{
        root:{
          outline:'none !important',
        }
      }, 
    },
    "palette": {
      InvisageColors: {
        yellow: yellow,
        black: black,
        white: white,
        grey1: grey1,
        grey2: grey2,
        grey3: grey3,
        grey4: grey4,
        grey5: grey5,
        grey6: grey6,
      },
      "common": {
        "black": black,
        "white": white
      },
      "type": "dark",
      "primary": {
        "main": primary,
        "light": primary,
        "dark": primary,
        "contrastText": "rgba(0, 0, 0, 0.87)"
      },
      "secondary": {
        "main": secondary,
        "light": secondary,
        "dark": secondary,
        "contrastText": "rgba(0, 0, 0, 0.87)"
      },
      "error": {
        "main": error,
        "light": error,
        "dark": error,
        "contrastText": white
      },
      "warning": {
        "main": warning,
        "light": warning,
        "dark": warning,
        "contrastText": "rgba(0, 0, 0, 0.87)"
      },
      "info": {
        "main": info,
        "light": info,
        "dark": info,
        "contrastText": white
      },
      "success": {
        "main": success,
        "light": success,
        "dark": success,
        "contrastText": "rgba(0, 0, 0, 0.87)"
      },
      "grey": {
        "50": "#fafafa",
        "100": "#f5f5f5",
        "200": "#eeeeee",
        "300": "#e0e0e0",
        "400": "#bdbdbd",
        "500": "#9e9e9e",
        "600": "#757575",
        "700": "#616161",
        "800": "#424242",
        "900": "#212121",
        "A100": "#d5d5d5",
        "A200": "#aaaaaa",
        "A400": "#303030",
        "A700": "#616161"
      },
      "contrastThreshold": 3,
      "tonalOffset": 0.2,
      "text": {
        "primary": white,
        "secondary": "rgba(255, 255, 255, 0.7)",
        "disabled": "rgba(255, 255, 255, 0.5)",
        "hint": "rgba(255, 255, 255, 0.5)",
        "icon": "rgba(255, 255, 255, 0.5)"
      },
      "divider": "rgba(255, 255, 255, 0.12)",
      "background": {
        // "paper": "rgba(0, 0, 0, 0.7)",
        "default": "#121212",
        "level2": "#333",
        "level1": "#212121"
      },
      "action": {
        "active": white,
        "hover": "rgba(255, 255, 255, 0.08)",
        "hoverOpacity": 0.08,
        "selected": "rgba(255, 255, 255, 0.16)",
        "selectedOpacity": 0.16,
        "disabled": "rgba(255, 255, 255, 0.3)",
        "disabledBackground": "rgba(255, 255, 255, 0.12)"
      }
    },
    "props": {},
    "shadows": [
      "none",
      "0px 2px 1px -1px rgba(0,0,0,0.2),0px 1px 1px 0px rgba(0,0,0,0.14),0px 1px 3px 0px rgba(0,0,0,0.12)",
      "0px 3px 1px -2px rgba(0,0,0,0.2),0px 2px 2px 0px rgba(0,0,0,0.14),0px 1px 5px 0px rgba(0,0,0,0.12)",
      "0px 3px 3px -2px rgba(0,0,0,0.2),0px 3px 4px 0px rgba(0,0,0,0.14),0px 1px 8px 0px rgba(0,0,0,0.12)",
      "0px 2px 4px -1px rgba(0,0,0,0.2),0px 4px 5px 0px rgba(0,0,0,0.14),0px 1px 10px 0px rgba(0,0,0,0.12)",
      "0px 3px 5px -1px rgba(0,0,0,0.2),0px 5px 8px 0px rgba(0,0,0,0.14),0px 1px 14px 0px rgba(0,0,0,0.12)",
      "0px 3px 5px -1px rgba(0,0,0,0.2),0px 6px 10px 0px rgba(0,0,0,0.14),0px 1px 18px 0px rgba(0,0,0,0.12)",
      "0px 4px 5px -2px rgba(0,0,0,0.2),0px 7px 10px 1px rgba(0,0,0,0.14),0px 2px 16px 1px rgba(0,0,0,0.12)",
      "0px 5px 5px -3px rgba(0,0,0,0.2),0px 8px 10px 1px rgba(0,0,0,0.14),0px 3px 14px 2px rgba(0,0,0,0.12)",
      "0px 5px 6px -3px rgba(0,0,0,0.2),0px 9px 12px 1px rgba(0,0,0,0.14),0px 3px 16px 2px rgba(0,0,0,0.12)",
      "0px 6px 6px -3px rgba(0,0,0,0.2),0px 10px 14px 1px rgba(0,0,0,0.14),0px 4px 18px 3px rgba(0,0,0,0.12)",
      "0px 6px 7px -4px rgba(0,0,0,0.2),0px 11px 15px 1px rgba(0,0,0,0.14),0px 4px 20px 3px rgba(0,0,0,0.12)",
      "0px 7px 8px -4px rgba(0,0,0,0.2),0px 12px 17px 2px rgba(0,0,0,0.14),0px 5px 22px 4px rgba(0,0,0,0.12)",
      "0px 7px 8px -4px rgba(0,0,0,0.2),0px 13px 19px 2px rgba(0,0,0,0.14),0px 5px 24px 4px rgba(0,0,0,0.12)",
      "0px 7px 9px -4px rgba(0,0,0,0.2),0px 14px 21px 2px rgba(0,0,0,0.14),0px 5px 26px 4px rgba(0,0,0,0.12)",
      "0px 8px 9px -5px rgba(0,0,0,0.2),0px 15px 22px 2px rgba(0,0,0,0.14),0px 6px 28px 5px rgba(0,0,0,0.12)",
      "0px 8px 10px -5px rgba(0,0,0,0.2),0px 16px 24px 2px rgba(0,0,0,0.14),0px 6px 30px 5px rgba(0,0,0,0.12)",
      "0px 8px 11px -5px rgba(0,0,0,0.2),0px 17px 26px 2px rgba(0,0,0,0.14),0px 6px 32px 5px rgba(0,0,0,0.12)",
      "0px 9px 11px -5px rgba(0,0,0,0.2),0px 18px 28px 2px rgba(0,0,0,0.14),0px 7px 34px 6px rgba(0,0,0,0.12)",
      "0px 9px 12px -6px rgba(0,0,0,0.2),0px 19px 29px 2px rgba(0,0,0,0.14),0px 7px 36px 6px rgba(0,0,0,0.12)",
      "0px 10px 13px -6px rgba(0,0,0,0.2),0px 20px 31px 3px rgba(0,0,0,0.14),0px 8px 38px 7px rgba(0,0,0,0.12)",
      "0px 10px 13px -6px rgba(0,0,0,0.2),0px 21px 33px 3px rgba(0,0,0,0.14),0px 8px 40px 7px rgba(0,0,0,0.12)",
      "0px 10px 14px -6px rgba(0,0,0,0.2),0px 22px 35px 3px rgba(0,0,0,0.14),0px 8px 42px 7px rgba(0,0,0,0.12)",
      "0px 11px 14px -7px rgba(0,0,0,0.2),0px 23px 36px 3px rgba(0,0,0,0.14),0px 9px 44px 8px rgba(0,0,0,0.12)",
      "0px 11px 15px -7px rgba(0,0,0,0.2),0px 24px 38px 3px rgba(0,0,0,0.14),0px 9px 46px 8px rgba(0,0,0,0.12)"
    ],
    
    "shape": {
      "borderRadius": 4
    },
    "transitions": {
      "easing": {
        "easeInOut": "cubic-bezier(0.4, 0, 0.2, 1)",
        "easeOut": "cubic-bezier(0.0, 0, 0.2, 1)",
        "easeIn": "cubic-bezier(0.4, 0, 1, 1)",
        "sharp": "cubic-bezier(0.4, 0, 0.6, 1)"
      },
      "duration": {
        "shortest": 150,
        "shorter": 200,
        "short": 250,
        "standard": 300,
        "complex": 375,
        "enteringScreen": 225,
        "leavingScreen": 195
      }
    },
    "zIndex": {
      "mobileStepper": 1000,
      "speedDial": 1050,
      "appBar": 1100,
      "drawer": 1200,
      "modal": 1300,
      "snackbar": 1400,
      "tooltip": 1500
    },
    "nprogress": {
      "color": white
    }
};